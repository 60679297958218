import { getContract } from 'thirdweb';
import { client } from '../utils/client';
import { polygon } from 'thirdweb/chains';

export const useBattleContract = () => {
    const address = process.env.NEXT_PUBLIC_BATTLE_TOKEN;

    if (!address) {
        throw new Error('NEXT_PUBLIC_BATTLE_TOKEN is not defined in the environment variables');
    }

    return getContract({
        client,
        address,
        chain: polygon,
    });
};

export const useAmberContract = () => {
    const address = process.env.NEXT_PUBLIC_AMBER_TOKEN;

    if (!address) {
        throw new Error('NEXT_PUBLIC_AMBER_TOKEN is not defined in the environment variables');
    }

    return getContract({
        client,
        address,
        chain: polygon,
    });
};

export const useNftContract = () => {
    const address = process.env.NEXT_PUBLIC_NFT_TOKEN;

    if (!address) {
        throw new Error('NEXT_PUBLIC_NFT_TOKEN is not defined in the environment variables');
    }

    return getContract({
        client,
        address,
        chain: polygon,
    });
};

export const useNftGnomeContract = () => {
    const address = process.env.NEXT_PUBLIC_NFT_GNOME_TOKEN;

    if (!address) {
        throw new Error('NEXT_PUBLIC_NFT_GNOME_TOKEN is not defined in the environment variables');
    }

    return getContract({
        client,
        address,
        chain: polygon,
    });
};

export const useUniContract = () => {
    const address = process.env.NEXT_PUBLIC_UNI_TOKEN;

    if (!address) {
        throw new Error('NEXT_PUBLIC_UNI_TOKEN is not defined in the environment variables');
    }

    return getContract({
        client,
        address,
        chain: polygon,
    });
};

export const useStakingContract = () => {
    const address = process.env.NEXT_PUBLIC_STAKING_TOKEN;

    if (!address) {
        throw new Error('NEXT_PUBLIC_STAKING_TOKEN is not defined in the environment variables');
    }

    return getContract({
        client,
        address,
        chain: polygon,
    });
};

export const useUsdcContract = () => {
    const address = process.env.NEXT_PUBLIC_USDC_TOKEN;

    if (!address) {
        throw new Error('NEXT_PUBLIC_USDC_TOKEN is not defined in the environment variables');
    }

    return getContract({
        client,
        address,
        chain: polygon,
    });
};