import React from 'react';
import Image from 'next/image';
import Link from 'next/link';

export default function Logo() {
    return (
      <div className='flex 2xl:flex-1 items-center justify-start'>
        <Link
                href='/'
                onClick={() => {
                    window.scrollTo(0, 0);
                }}
            >
          <div className='flex items-center justify-center group gap-2 -ml-6'>
            <div className='relative w-[100px] flex items-center justify-center'>
              <Image
                    className='ml-16 group-hover:drop-shadow-[0px_0px_5px_gray]'
                    src='/logo/SpringLogo.webp'
                    style={{ width: '140px', height: 'auto' }}
                    width={40}
                    height={40}
                    alt='Logo Decision'
                    priority
                />
              {/*<Image*/}
              {/*      className='lg:group-hover:opacity-0 opacity-100 transition-opacity duration-100 ml-[2px]'*/}
              {/*      src='/logo/diamond.webp'*/}
              {/*      style={{ width: 'auto', height: 'auto' }}*/}
              {/*      width={40}*/}
              {/*      height={40}*/}
              {/*      alt='Logo Decision'*/}
              {/*      priority*/}
              {/*  />*/}

              {/*<div className='hidden lg:block opacity-0 group-hover:opacity-100 transition-opacity duration-800 ml-[3px]'>*/}
              {/*  <video loop autoPlay muted playsInline>*/}
              {/*    <source src='/logo/logo.mov' type='video/quicktime' />*/}
              {/*    <source src='/logo/logo.webm' type='video/webm' />*/}
              {/*  </video>*/}
              {/*</div>*/}
            </div>

          </div>
        </Link>
      </div>
    );
}
